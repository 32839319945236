/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from 'react-vertical-timeline-component'

import 'react-vertical-timeline-component/style.min.css'
import _, { reject } from 'lodash'
import {
	Box,
	Flex,
	Icon,
	Skeleton,
	Stack,
	Text,
	Button,
	Input,
	useDisclosure,
	VStack,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	DrawerHeader,
	Divider,
	HStack,
	Checkbox,
	useToast,
	Tooltip,
} from '@chakra-ui/react'
import DrillDownTable, {
	TH,
	TR,
	TD,
} from '../../../../components/dashboard-table'
import ReactExport from 'react-data-export'
import {
	useGetAccounts,
	useMutateOutlier,
	useMutateTarget,
	useMutateKpiCodesByReportees,
	useMutateCheckAccountChannelLevelTarget,
	useSIPTopDownBottomUp,
	useCheckFreeze,
	useTargetUpdateTimeline,
	useGetReportees,
} from '../../../../hooks/dashboard.hooks'
import { BiDownload } from 'react-icons/bi'
import { BiHome } from 'react-icons/bi'
import { RiSettings2Fill } from 'react-icons/ri'
import { calculateStartEndDate } from '../../../../utils/dashboard.utils'
import { useDashboardContext } from '../../../../context/dashboard.context'
import { useAuthContext } from '../../../../context/auth.context'
import { dashboardConst } from '../../../../utils/action.constant'
import {
	noOfmonths,
	monthsName,
	instructionsData,
	totalTargetType,
	periodFlag,
} from './common'
import {
	AiFillEdit,
	AiOutlinePlusCircle,
	AiOutlineFieldTime,
} from 'react-icons/ai'
import moment from 'moment'
// import { Timeline, TimelineEvent } from 'react-event-timeline'
import { formatDueDateForTimelineCurrDate } from '../../../../utils/date.utils'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../repository/repo.utils'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function WidgetTopDownBottomUpView() {
	const initRef = React.useRef()
	const [download, setDownload] = useState(false)
	const [tempDownload, setTemplateDownload] = useState(false)
	const [isTopDown, setIsTopDown] = useState(false)
	const [isOpenTarget, setIsOpenTarget] = useState(false)
	const [kpiCodesList, setKpiCodes] = useState([])
	const [accCodesList, setAccCodes] = useState([])
	const [reporteesList, setReporteesList] = useState([])
	const [attribute, setAttribute] = useState('')
	const [selectedReportee, setSelectedReportee] = useState({})
	const [activeUser, setActiveUser] = useState({})
	const [accLevelAccess, setAccLevelAccess] = useState(false)
	const [isEditing, setIsEditing] = useState(false)
	const [timelineData, setTimelineData] = useState([])
	const [editAccTarget, setEditAccTarget] = useState(false)
	const [insertInProgress, setInsertInProgress] = useState(false)
	const [accLoading, setAccLoading] = useState(false)
	const [showUserDetails, setShowUserDetails] = useState(true)
	const [outlierKpiDetails, setOutlierKpiDetails] = useState(null)
	const [activePeriodTimeline, setActivePeriodTimeline] = useState('')

	const toast = useToast({ duration: 5000 })
	const {
		isOpen: isTimelineOpen,
		onClose: onCloseTimeline,
		onOpen: onOpenTimeline,
	} = useDisclosure()

	const {
		state: {
			queryFilters,
			bottomUpList,
			filterOptions,
			sipReporteeList: drillDownReporteeList,
		},
		dispatch: dashboardDispatch,
	} = useDashboardContext()

	const {
		state: { authData },
	} = useAuthContext()

	useEffect(() => {
		let reportee = { ...authData }
		if (reportee?.asset_id) {
			setIsTopDown(queryFilters.selectedSubApplication?.value === 224)
			addReporteeToStack(reportee, true)
			setReporteesList([])
			addReportee([])
			setActiveUser(reportee)
			setShowUserDetails(true)
		}
	}, [queryFilters.selectedSubApplication?.value])

	useEffect(() => {
		let reportee = { ...authData }
		if (reportee?.asset_id) {
			addReporteeToStack(reportee, true)
			setReporteesList([])
			addReportee([])
			setActiveUser(reportee)
			setShowUserDetails(true)
		}
	}, [queryFilters.selectedWorkforceTag?.value])

	useEffect(() => {
		let reportee = activeUser
		if (reportee?.asset_id) {
			loadTeamMembers(reportee)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		queryFilters.selectedPeriod,
		queryFilters.selectedPeriodType,
		queryFilters.selectedKPIType,
		queryFilters.selectedKPICategory,
		queryFilters.selectedKPIProduct,
		queryFilters.selectedYear,
		queryFilters.selectedBuilding,
		queryFilters.selectedWorkforce,
		queryFilters.selectedWorkforceTag,
		queryFilters.selectedTagType1,
		queryFilters.selectedTag1,
		queryFilters.selectedRole,
	])

	useEffect(() => {
		let reportee = { ...queryFilters.selectedCreator }
		if (reportee?.label === 'All') {
			reportee = { ...authData }
		} else {
			clearReporteeToStack()
		}
		if (reportee?.asset_id) {
			addReportee([])
			setActiveUser(reportee)
			setReporteesList([reportee])
			addReporteeToStack(reportee, true)
			setShowUserDetails(true)
		}
	}, [queryFilters.selectedCreator])

	useEffect(() => {
		if (outlierKpiDetails?.widget_type_id) {
			updateOutlier(outlierKpiDetails)
		}
	}, [outlierKpiDetails])

	const { mutate: mutateTopDownBottomUpTargets } = useSIPTopDownBottomUp()
	const { mutate: checkIsFreeze } = useCheckFreeze()
	const { mutate: mutateAccountLevelReportees } = useGetAccounts()
	const { mutate: mutateTeamReportees, isLoading } = useGetReportees()

	const { mutate: mutateOutlier } = useMutateOutlier()
	const { mutate: mutateTarget } = useMutateTarget()
	const { mutate: mutateKpiCodes } = useMutateKpiCodesByReportees()
	const { mutate: mutateCheckAccountChannelLevel } =
		useMutateCheckAccountChannelLevelTarget()
	const { mutate: targetTimeline } = useTargetUpdateTimeline()

	const loadTeamMembers = reportee => {
		const onSuccess = {
			onSuccess: data => {
				setAccCodes([])
				setReporteesList(data)
				addReportee(data)
				setAccLevelAccess(false)
			},
		}
		addReporteeToStack(reportee)
		if (showUserDetails || reportee?.asset_flag_frontline === 0) {
			setAccLevelAccess(false)
			setReporteesList([reportee])
			loadTopDownBottomUpTargets(reportee)
			getKpiCodes(reportee, 1)
			return
		}

		if (
			reportee?.asset_flag_frontline === 1 &&
			(queryFilters.selectedWorkforceTag?.value === 358 ||
				queryFilters.selectedWorkforceTag?.value === 0)
		) {
			checkAccChannelTarget(reportee, isAccLevel => {
				setEditAccTarget(isAccLevel ? true : false)
				setAccLevelAccess(true)
				getAccCodes(reportee)
				setReporteesList([])
				loadTopDownBottomUpTargets(reportee, true)
				setAccLoading(true)
			})
		} else if (reportee?.asset_flag_frontline === 2) {
			let {
				selectedWorkforceTag,
				selectedTagType1,
				selectedTag1,
				selectedBuilding,
				selectedWorkforce,
				selectedRole,
				selectedCreator,
			} = queryFilters
			mutateTeamReportees(
				{
					manager_asset_id: reportee.asset_id,
					workforce_tag_id: selectedWorkforceTag?.value || 0,
					workforce_id: selectedWorkforce?.value || 0,
					asset_type_id: selectedRole?.value || 0,
					account_id: selectedBuilding?.value || 0,
					// target_asset_id: selectedCreator?.value || 0,
					asset_tag_type_id_1: selectedTagType1?.value || 0,
					asset_tag_id_1: selectedTag1?.value || 0,
					asset_tag_id_2: 0,
					asset_tag_id_3: 0,
					flag: 0,
					page_start: 0,
					page_limit: 500,
					start_from: 0,
					limit_value: 500,
				},
				onSuccess
			)
			loadTopDownBottomUpTargets(reportee)
		}
		getKpiCodes(reportee)
	}

	const loadTopDownBottomUpTargets = (reportee, isAccLevel) => {
		const {
			selectedPeriodType,
			selectedPeriod,
			selectedWorkforceTag,
			selectedKPIProduct,
			selectedYear,
			selectedKPIType,
			selectedKPICategory,
			selectedCluster,
			selectedTagType1,
			selectedTag1,
			selectedBuilding,
			selectedWorkforce,
		} = queryFilters
		let { startDate, endDate } = calculateStartEndDate({
			selectedPeriod,
			selectedPeriodType,
			selectedYear,
		})
		const onSuccess = {
			onSuccess: data => {
				if (data && Array.isArray(data)) {
					addTargets(data)
				}
			},
		}
		let flag = isAccLevel ? 9 : 6
		// level id 6 individual target else 9 acc level target
		mutateTopDownBottomUpTargets(
			{
				workforce_tag_id: selectedWorkforce?.value || 0,
				manager:
					(isAccLevel || showUserDetails
						? reportee?.manager_asset_id
						: reportee?.asset_id) || 0,
				asset_id: reportee?.asset_id || 0,
				segment: !!selectedWorkforceTag ? selectedWorkforceTag.value : 0,
				startDate,
				endDate,
				product_id: selectedKPIProduct?.value || 0,
				widget_type_id: selectedKPIType?.value || 0,
				widget_type_category_id: selectedKPICategory?.value || 0,
				flag,
				period_type_id: 0,
				page_start: 0,
				page_limit: 10,
				flag_type: 2,
				asset_type_id: 0,
				level_id: 0,
				account_id: selectedBuilding?.value || 0,
				asset_tag_id_1: selectedTag1?.value || 0,
				asset_tag_id_2: 0,
				asset_tag_type_id_1: selectedTagType1?.value || 0,
				timeline_id: selectedPeriodType?.value || 0,
				financial_year: selectedYear?.value?.replaceAll(' ', '') || '',
				cluster_tag_id: selectedCluster?.value || 0,
			},
			onSuccess
		)
	}

	const getKpiCodes = (reportee, flag) => {
		setKpiCodes([])
		let {
			selectedPeriod,
			// selectedPeriodType,
			selectedYear,
			selectedKPICategory,
			selectedKPIProduct,
			selectedKPIType,
			selectedTagType1,
			selectedTag1,
			selectedBuilding,
			selectedWorkforce,
			selectedWorkforceTag,
			selectedRole,
		} = queryFilters
		// let { startDate, endDate } = calculateStartEndDate({
		//   selectedPeriod,
		//   selectedPeriodType,
		//   selectedYear,
		// });
		let payload = {
			product_id: selectedKPIProduct?.value || 0,
			timeline_id: 0,
			asset_type_id:
				(reportee?.asset_flag_frontline === 1
					? reportee?.asset_type_id
					: selectedRole?.value) || 0,
			asset_id: reportee?.asset_id,
			start_from: 0,
			limit_value: 500,
			period_start_datetime: '',
			period_end_datetime: '',
			financial_year: selectedYear?.value?.replaceAll(' ', '') || '',
			widget_type_id: selectedKPIType?.value || 0,
			widget_type_category_id: selectedKPICategory?.value || 0,
			asset_tag_type_id_1: selectedTagType1?.value || 0,
			asset_tag_id_1: selectedTag1?.value || 0,
			asset_tag_id_2: 0,
			workforce_tag_id: selectedWorkforceTag?.value || 0,
			account_id: selectedBuilding?.value || 0,
			workforce_id: selectedWorkforce?.value || 0,
			flag: !!flag ? flag : 0,
		}
		mutateKpiCodes(payload, {
			onSuccess: data => {
				setKpiCodes(
					data.filter(
						item =>
							item[periodFlag[selectedPeriod?.label]] === 1 || item.flag_y === 1
					)
				)
				addKpiCodes(data)
			},
		})
	}

	const getAccCodes = reportee => {
		setAccCodes([])
		let { selectedPeriod, selectedPeriodType, selectedYear } = queryFilters
		let { startDate, endDate } = calculateStartEndDate({
			selectedPeriod,
			selectedPeriodType,
			selectedYear,
		})
		let payload = {
			account_id: 0,
			activity_type_category_id: 53,
			asset_id: reportee?.asset_id,
			manager_asset_id: reportee?.manager_asset_id,
			widget_type_id: 0,
			period_start_datetime: startDate,
			period_end_datetime: endDate,
			financial_year: selectedYear?.value?.replaceAll(' ', '') || '',
			flag_type: 2,
			start_from: 0,
			limit_value: 10,
		}
		mutateAccountLevelReportees(payload, {
			onSuccess: data => {
				if (data && data.length) {
					let obj = {}
					data.forEach(item => {
						if (obj[item.customer_account_code]) {
							obj[item.customer_account_code]['widget_type_id'].push(
								item.widget_type_id
							)
						} else {
							obj[item.customer_account_code] = {
								...item,
								widget_type_id: [item.widget_type_id],
							}
						}
					})
					let arr = Object.values(obj)
					setAccCodes(arr)
				} else {
					let user = drillDownReporteeList[drillDownReporteeList.length - 1]
					setAccLevelAccess(false)
					if (user?.asset_id) {
						if (user?.asset_id === queryFilters.selectedCreator?.asset_id) {
							setShowUserDetails(true)
						} else if (user?.asset_id === reportee?.asset_id) {
							user = drillDownReporteeList[drillDownReporteeList.length - 2]
						}
						setActiveUser(user)
					}
					toast({
						title: `${
							queryFilters.selectedWorkforceTag?.value === 184
								? 'Channel Code'
								: 'Account Code'
						} not present.`,
						position: `top-right`,
						isClosable: true,
						status: 'error',
					})
				}
				setAccLoading(false)
			},
		})
	}

	const checkAccChannelTarget = (reportee, cb) => {
		let {
			selectedPeriodType,
			selectedYear,
			selectedKPIProduct,
			selectedBuilding,
			selectedWorkforce,
		} = queryFilters
		let { startDate, endDate } = calculateStartEndDate({})
		let payload = {
			asset_id: reportee?.asset_id,
			period_start_datetime: startDate,
			period_end_datetime: endDate,
			financial_year: selectedYear?.value?.replaceAll(' ', '') || '',
			product_id: selectedKPIProduct?.value || 0,
			workforce_tag_id: 0,
			workforce_id: selectedWorkforce?.value || 0,
			asset_type_id: 0,
			level_id: 0,
			account_id: selectedBuilding?.value || 0,
			// asset_tag_id_1: selectedTagType1?.value || 0,
			timeline_id: selectedPeriodType?.value || 0,
			start_from: 0,
			limit_value: 10,
		}
		mutateCheckAccountChannelLevel(payload, {
			onSuccess: data => {
				let bool = false
				if (data?.[0]?.target_setting_flag_channel === 1) {
					bool = true
				}
				cb(bool)
			},
		})
	}
	const checkFreeze = (widget_type_id, asset_id = null) => {
		return new Promise(resolve => {
			const { selectedPeriodType, selectedPeriod, selectedYear } = queryFilters
			let { startDate, endDate } = calculateStartEndDate({
				selectedPeriod,
				selectedPeriodType,
				selectedYear,
			})
			let payload = {
				asset_type_id: 0,
				cluster_tag_id:
					selectedReportee?.asset_tag_id_1 || activeUser?.asset_tag_id_1,
				vertical_tag_id:
					selectedReportee?.asset_tag_id_1 || activeUser?.asset_tag_id_1,
				sub_vertical_tag_id:
					selectedReportee?.asset_tag_id_1 || activeUser?.asset_tag_id_1,
				timeline_id: selectedPeriodType?.value || 0,
				financial_year: selectedYear?.value?.replaceAll(' ', '') || '',
				flag: 2,
				is_freeze: 0,
				level_id: 0,
				account_id: selectedReportee?.account_id || activeUser?.account_id,
				widget_type_id: widget_type_id || 0,
				start_datetime: startDate,
				end_datetime: endDate,
				period_type_id: selectedPeriodType?.value,
				start_from: 0,
				limit_value: 10,
				workforce_tag_id:
					selectedReportee.workforce_tag_id || activeUser.workforce_tag_id,
				asset_id: !!asset_id
					? asset_id
					: selectedReportee.asset_id || activeUser.asset_id,
				log_asset_id: authData.asset_id,
			}
			checkIsFreeze(payload, {
				onSuccess: data => {
					resolve(data)
				},
				onError: err => {
					reject(err)
				},
			})
		})
	}

	const insertUpdateTarget = async (isUpdate, widget_type_id, month) => {
		if (!isFinite(attribute)) {
			toast({
				title: `Target is required`,
				position: `top-right`,
				isClosable: true,
				status: 'error',
			})
			return false
		} else {
			try {
				setInsertInProgress(true)
				let resp = await checkFreeze(widget_type_id)
				if (resp?.freeze_count === 0) {
					const {
						selectedPeriodType,
						selectedPeriod,
						selectedKPIProduct,
						selectedYear,
						selectedTag1,
						selectedTagType1,
						selectedKPICategory,
						selectedWorkforceTag,
					} = queryFilters
					let { startDate, endDate, startYear, endYear } =
						calculateStartEndDate({
							selectedPeriod,
							selectedPeriodType,
							selectedYear,
						})
					let stDate = moment(startDate)
					let edDate = moment(endDate)
					let mnths = []
					while (edDate > stDate) {
						mnths.push(stDate.month() + 1)
						stDate = stDate.add(1, 'months')
					}
					setIsOpenTarget(false)
					let payload = {}
					let target = parseFloat(attribute)
					let months = noOfmonths[selectedPeriodType.value]
					if (selectedPeriodType?.label !== 'QTD') {
						target /= months
					}
					let obj = {}
					if (selectedPeriodType?.label === 'QTD') {
						Object.entries(monthsName).forEach(([num, mnth]) => {
							obj[`${mnth}_total_target_value`] =
								mnth === month ? target : undefined
						})
					} else {
						Object.entries(monthsName).forEach(([num, mnth]) => {
							obj[`${mnth}_total_target_value`] = mnths.includes(+num)
								? target
								: undefined
						})
					}
					if (isUpdate) {
						payload = {
							...obj,
							entity_target_mapping_id:
								selectedReportee?.entity_target_mapping_id || 0,
							target_asset_id:
								selectedReportee?.asset_id || activeUser.asset_id,
							asset_id: selectedReportee?.asset_id || activeUser.asset_id,
							customer_account_code:
								selectedReportee?.customer_account_code || '',
							customer_account_name:
								selectedReportee?.customer_account_name || '',
							target: parseFloat(attribute),
							total_target_value: parseFloat(attribute),
							widget_type_id,
							isUpdate,
							flag_type: 2,
							level_id: accLevelAccess ? 9 : 6,
							period_type_id: selectedPeriodType?.value,
							period_start_datetime: startDate,
							period_end_datetime: endDate,
							log_asset_id: authData.asset_id,
							asset_tag_type_id_1: selectedTagType1?.value || 0,
							asset_tag_id_1: selectedTag1?.value || 0,
							financial_year: `${startYear}-${endYear}`,
							widget_type_name: '',
							workforce_tag_id: selectedWorkforceTag?.value || 0,
							cluster_tag_id: 0,
							asset_tag_id_1: selectedTag1?.value || 0,
							asset_tag_id_2: 0,
							asset_tag_id_3: 0,
							entity_target_inline: '{}',
							flag_is_outlier: 0,
							flag_is_bulk: 0,
							timeline_id: selectedPeriodType?.value,
							customer_account_type_id: 0,
							product_id: selectedKPIProduct?.value,
							widget_type_category_id: selectedKPICategory?.value,
							activity_id: 1,
						}
					} else {
						payload = {
							total_target_value: parseFloat(attribute),
							...obj,
							financial_year: `${startYear}-${endYear}`,
							widget_type_name: '',
							workforce_tag_id: selectedWorkforceTag?.value || 0,
							cluster_tag_id: 0,
							asset_tag_type_id_1: selectedTagType1?.value || 0,
							asset_tag_id_1: selectedTag1?.value || 0,
							asset_tag_id_2: 0,
							asset_tag_id_3: 0,
							entity_target_inline: '{}',
							level_id: accLevelAccess ? 9 : 6,
							flag_is_outlier: 0,
							flag_is_bulk: 0,
							flag_type: 2,
							timeline_id: selectedPeriodType?.value,
							period_type_id: selectedPeriodType?.value,
							period_start_datetime: startDate,
							period_end_datetime: moment(endDate)
								.endOf('month')
								.format('YYYY-MM-DD'),
							customer_account_type_id: 0,
							customer_account_code:
								selectedReportee?.customer_account_code || '',
							customer_account_name:
								selectedReportee?.customer_account_name || '',
							product_id: selectedKPIProduct?.value,
							widget_type_id: widget_type_id,
							widget_type_category_id: selectedKPICategory?.value,
							activity_id: 1,
							asset_id: selectedReportee?.asset_id || activeUser.asset_id,
							log_asset_id: authData.asset_id,
						}
					}
					mutateTarget(payload, {
						onSuccess: () => {
							toast({
								title: `Target successfully ${
									isUpdate ? 'updated' : 'inserted'
								}`,
								position: `top-right`,
								isClosable: true,
								status: 'success',
							})
							let reportee = activeUser
							loadTopDownBottomUpTargets(reportee, accLevelAccess)
							setInsertInProgress(false)
							setAttribute('')
							setIsOpenTarget(false)
							setSelectedReportee({})
						},
					})
				} else {
					setInsertInProgress(false)
					toast({
						title: `Cannot update target for freezed KPI ${resp?.Level} level`,
						position: `top-right`,
						isClosable: true,
						status: 'error',
					})
				}
			} catch {}
		}
	}

	const updateOutlier = async user => {
		if (user?.widget_type_id) {
			let { widget_type_id, flag_is_outlier, asset_id } = user
			try {
				let resp = await checkFreeze(widget_type_id, asset_id)
				if (resp?.freeze_count === 0) {
					const { selectedPeriodType, selectedPeriod, selectedYear } =
						queryFilters
					let { startDate, endDate } = calculateStartEndDate({
						selectedPeriod,
						selectedPeriodType,
						selectedYear,
					})
					mutateOutlier(
						{
							target_asset_id: asset_id,
							flag_type: 2,
							timeline_id: selectedPeriodType?.value || 0,
							period_start_datetime: startDate,
							period_end_datetime: endDate,
							asset_id,
							flag_is_outlier: flag_is_outlier ? 1 : 0,
							widget_type_id,
						},
						{
							onSuccess: data => {
								if (data?.[0].query_status === 0) {
									toast({
										title: `Outlier updated successfully`,
										position: `top-right`,
										isClosable: true,
										status: 'success',
									})
									let list = bottomUpList
									let index = list.findIndex(
										item =>
											item.asset_id === asset_id &&
											item.widget_type_id === widget_type_id
									)
									if (index > -1) {
										let item = list[index]
										item['flag_is_outlier'] = flag_is_outlier ? 1 : 0
										list.splice(index, 1, item)
										updateOutlierDash(list)
									}
								} else {
									toast({
										title: `Error occured while updating outlier`,
										position: `top-right`,
										isClosable: true,
										status: 'error',
									})
								}
								setOutlierKpiDetails(null)
							},
						}
					)
				} else {
					toast({
						title: `Cannot update outlier of freezed kpi`,
						position: `top-right`,
						isClosable: true,
						status: 'error',
					})
					setOutlierKpiDetails(null)
				}
			} catch {}
		}
	}

	const setQueryFilterValue = (filterName, value) => {
		return dashboardDispatch({
			type: dashboardConst.UPDATE_QUERY_FILTERS,
			filterName,
			value,
		})
	}

	const updateOutlierDash = value => {
		dashboardDispatch({
			type: dashboardConst.UPDATE_BOTTOMUP,
			bottomUp: value,
		})
	}

	const addTargets = bottomUp => {
		dashboardDispatch({
			type: dashboardConst.ADD_BOTTOMUP,
			bottomUp,
		})
	}

	const addKpiCodes = data => {
		dashboardDispatch({
			type: dashboardConst.ADD_KPI_CODES,
			payload: data,
		})
	}

	const addReportee = data => {
		dashboardDispatch({
			type: dashboardConst.ADD_REPORTEES,
			payload: data,
		})
	}
	const renderHeadings = () => {
		let heads = []
		if (accLevelAccess) {
			heads = ['Account Code', 'Account Name']
		} else {
			heads = [
				'Employee Name',
				'Employee Code',
				'Employee Email',
				'Employee Desk ID',
				'Manager Name',
				'Manager Code',
				'Manager Email',
				'Role',
				'Segment',
				'Vertical',
				'Circle',
				// 'Outlier',
			]
		}
		heads = [
			...heads,
			...kpiCodesList.map(kpi => (
				<VStack minWidth={100} justifyContent={'flex-start'}>
					<Box color='#44474a' fontWeight='bold' fontSize={14}>
						{kpi.widget_type_code}
					</Box>{' '}
					<Box fontSize={10}>{kpi.widget_type_name}</Box>
					<Box color='#44474a' fontSize={10}>
						{`(${kpi.widget_type_measurement_unit} ${kpi.widget_type_measurement_name})`}
					</Box>
				</VStack>
			)),
		]
		heads = heads.map((item, i) => (
			<TH rowSpan={2} key={i}>
				{item}
			</TH>
		))

		let tableHead = (
			<>
				<TR>
					<TH rowSpan={1}>#</TH>
					{heads}
				</TR>
			</>
		)
		return tableHead
	}

	const onClose = () => {
		setIsOpenTarget(false)
		setAttribute('')
	}

	const handleAttributeChange = e => {
		setAttribute(e.target.value)
	}

	const handlePopover = (
		item,
		widget_type_id,
		id,
		entity_target_mapping_id,
		target_value
	) => {
		setSelectedReportee({ ...item, widget_type_id, entity_target_mapping_id })
		setIsOpenTarget(true)
		setIsEditing(`${widget_type_id}-${id}`)
		setAttribute(target_value)
	}

	const handleTimeline = (item, kpi, period) => {
		const { widget_type_id } = kpi
		const { asset_id, asset_type_id, customer_account_code } = item
		const { selectedWorkforceTag } = queryFilters
		setActivePeriodTimeline(period)
		let params = {
			organization_id: authData.organization_id,
			asset_type_id: asset_type_id || activeUser.asset_type_id,
			device_os_id: 5,
			start_from: 0,
			limit_value: 500,
			widget_type_id,
			flag_type: 2,
			workforce_tag_id: selectedWorkforceTag?.value || 0,
			asset_id: asset_id || activeUser.asset_id,
			customer_account_code: customer_account_code || '',
		}
		targetTimeline(params, {
			onSuccess: res => {
				if (res.status === 200 && res.response?.length) {
					setTimelineData(res.response)
					onOpenTimeline()
				} else {
					toast({
						title: `Time logs are not present`,
						position: `top-right`,
						isClosable: true,
						status: 'error',
					})
				}
			},
			onError: err => {
				console.log(err, 'err')
			},
		})
	}

	const addReporteeToStack = (reportee, isManager) => {
		if (reportee?.asset_id) {
			dashboardDispatch({
				type: isManager
					? dashboardConst.ADD_SIP_RESOURCE
					: dashboardConst.SET_SIP_RESOURCE,
				reportee: reportee,
			})
		}
	}

	const clearReporteeToStack = () => {
		dashboardDispatch({
			type: dashboardConst.CLEAR_REPORTEE_STACK,
		})
	}

	const checkIsDisabled = user => {
		let bool = false
		if (!accLevelAccess) {
			if ([184, 185].includes(queryFilters.selectedWorkforceTag?.value)) {
				bool = false
			} else {
				// bool = user.asset_flag_frontline !== 1;
			}
		} else {
			bool = !editAccTarget
		}
		return bool
	}

	const checkOutlierDisabled = user => {
		let bool = !accLevelAccess && user.asset_flag_frontline !== 1
		return bool
	}

	const hasAccLevelTarget = user => {
		let targets = bottomUpList
		let targetAcc = targets.find(
			trgt => trgt.asset_id === user.asset_id && trgt.level_id === 9
		)
		return !!targetAcc
	}

	const getTargetValue = (kpi, item) => {
		let targets = bottomUpList
		let target = targets.find(
			trgt =>
				(accLevelAccess
					? trgt.customer_account_code === item.customer_account_code
					: trgt.asset_id === item.asset_id) &&
				trgt.widget_type_id === kpi.widget_type_id
		)
		if (target) {
			var { entity_target_mapping_id, level_id, flag_is_outlier } = target
		}
		return {
			entity_target_mapping_id,
			level_id,
			flag_is_outlier,
			targetObj: target,
		}
	}

	const getActiveQuarter = () => {
		let { selectedPeriod } = queryFilters
		let arr = []
		if (selectedPeriod.value === 4) {
			arr = [monthsName[4], monthsName[5], monthsName[6]]
		} else if (selectedPeriod.value === 5) {
			arr = [monthsName[7], monthsName[8], monthsName[9]]
		} else if (selectedPeriod.value === 6) {
			arr = [monthsName[10], monthsName[11], monthsName[12]]
		} else if (selectedPeriod.value === 7) {
			arr = [monthsName[1], monthsName[2], monthsName[3]]
		}
		return arr.map(i => `${i}_total_target_value`)
	}

	const makeTableData = () => {
		let data = []
		let total_targets = {}
		let _arr = reporteesList
		if (accLevelAccess) {
			_arr = accCodesList
		}
		if (Array.isArray(_arr) && _arr.length) {
			_arr?.forEach((item, i) => {
				let hasaccleveltrgt = hasAccLevelTarget(item)
				const isDisabled = checkIsDisabled(item)
				let obj = {}
				obj['s.no'] = i + 1
				if (accLevelAccess) {
					obj['Account Code'] = item.customer_account_code
					obj['Account Name'] = item.customer_account_name
				} else {
					obj['Employee Name'] = (
						<span
							className={`${
								item.asset_flag_frontline !== 0
									? 'cursor-pointer text-underline text-bold green-theme'
									: 'text-bold green-theme'
							}`}
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_DASHBOARD,
									action: 'Widget Top Down Bottom Up View',
									label: 'Load Data',
								})
								item.asset_flag_frontline !== 0 && loadData(item)
							}}
						>
							{item.operating_asset_first_name || 'TBA'}
						</span>
					)
					obj['Employee Code'] = item.operating_asset_customer_unique_id
					obj['Employee Email'] = item.operating_asset_email_id
					obj['Employee Desk ID'] = item.asset_id
					obj['Manager Name'] = item.manager_operating_asset_first_name
					obj['Manager Code'] = item.manager_asset_id
					obj['Manager Email'] = item.manager_operating_asset_email_id
					obj['Role'] = item.asset_type_name
					obj['Segment'] = item.workforce_tag_name || item.workforce_name
					obj['Vertical'] = item.asset_tag_name_1
					obj['Circle'] = item.account_name
				}
				kpiCodesList.forEach(kpi => {
					const {
						entity_target_mapping_id,
						level_id,
						flag_is_outlier,
						targetObj,
					} = getTargetValue(kpi, item)
					if (
						typeof targetObj?.[
							totalTargetType[queryFilters.selectedPeriod?.label]
						] === 'number'
					) {
						total_targets[kpi.widget_type_name] =
							(total_targets[kpi.widget_type_name] || 0) +
							targetObj?.[totalTargetType[queryFilters.selectedPeriod?.label]]
					}
					obj[kpi.widget_type_name] =
						kpi.asset_type_id === item.asset_type_id ||
						kpi.widget_type_id === item.widget_type_id ||
						(Array.isArray(item.widget_type_id) &&
							// item.widget_type_id.includes(kpi.widget_type_id)
							typeof targetObj?.[
								totalTargetType[queryFilters.selectedPeriod?.label]
							] === 'number') ? (
							<Flex alignItems={'center'} display={'inline-flex'}>
								{[
									...(queryFilters.selectedPeriodType?.label === 'QTD'
										? getActiveQuarter()
										: [totalTargetType[queryFilters.selectedPeriod?.label]]),
								].map(p => (
									<Popover
										isOpen={
											isEditing === `${kpi.widget_type_id}-${i}-${p}`
												? isOpenTarget
												: false
										}
										key={p}
										initRef={initRef}
										onClose={onClose}
										closeOnBlur={true}
										placement='bottom-start'
									>
										<PopoverTrigger
											isDisabled={
												(level_id === 6 && hasaccleveltrgt) || isDisabled
											}
										>
											<Box marginRight={1}>
												{typeof targetObj?.[p] === 'number' ? (
													isDisabled ? (
														<HStack>
															<Button>
																<Tooltip hasArrow label={targetObj?.[p]}>
																	<span>
																		{targetObj?.[p]?.toFixed(2)}{' '}
																		{typeof targetObj?.[p] === 'number' && (
																			<span>&nbsp;&nbsp;</span>
																		)}
																	</span>
																</Tooltip>
																<Box
																	as={AiFillEdit}
																	d='inline-flex'
																	color='grey'
																	position='relative'
																	style={{ cursor: 'not-allowed' }}
																/>
															</Button>
														</HStack>
													) : (
														<HStack>
															<Button>
																<Tooltip hasArrow label={targetObj?.[p]}>
																	<span>
																		{targetObj?.[p]?.toFixed(2)}{' '}
																		{typeof targetObj?.[p] === 'number' && (
																			<span>&nbsp;&nbsp;</span>
																		)}
																	</span>
																</Tooltip>
																{(accLevelAccess || level_id === 6) && (
																	<>
																		{![17, 8, 14].includes(
																			queryFilters.selectedPeriodType?.value
																		) && (
																			<Box
																				as={AiFillEdit}
																				onClick={() => {
																					sectionDetailedTrack({
																						category: GA_CATEGORY_DASHBOARD,
																						action:
																							'Widget Top Down Bottom Up View',
																						label: 'Popover Trigger',
																					})
																					handlePopover(
																						item,
																						kpi.widget_type_id,
																						`${i}-${p}`,
																						entity_target_mapping_id,
																						targetObj?.[p]
																					)
																				}}
																				d='inline-flex'
																				color='brand.800'
																				position='relative'
																			/>
																		)}
																		<Box
																			as={AiOutlineFieldTime}
																			onClick={() => {
																				sectionDetailedTrack({
																					category: GA_CATEGORY_DASHBOARD,
																					action:
																						'Widget Top Down Bottom Up View',
																					label: 'Timeline',
																				})
																				handleTimeline(item, kpi, p)
																			}}
																			d='inline-flex'
																			color='brand.800'
																			position='relative'
																		/>
																	</>
																)}
															</Button>
														</HStack>
													)
												) : (!accLevelAccess && hasaccleveltrgt) ||
												  isDisabled ? (
													<>
														{![17, 8, 14].includes(
															queryFilters.selectedPeriodType?.value
														) ? (
															<Button>
																<Box
																	as={AiOutlinePlusCircle}
																	d='inline-flex'
																	color='grey'
																	position='relative'
																	style={{ cursor: 'not-allowed' }}
																/>
															</Button>
														) : (
															'-'
														)}
													</>
												) : (
													<>
														{![17, 8, 14].includes(
															queryFilters.selectedPeriodType?.value
														) ? (
															<Button>
																<Box
																	as={AiOutlinePlusCircle}
																	onClick={() => {
																		sectionDetailedTrack({
																			category: GA_CATEGORY_DASHBOARD,
																			action: 'Widget Top Down Bottom Up View',
																			label: 'Popover',
																		})
																		handlePopover(
																			item,
																			kpi.widget_type_id,
																			`${i}-${p}`,
																			entity_target_mapping_id,
																			null
																		)
																	}}
																	d='inline-flex'
																	color='brand.800'
																	position='relative'
																/>
															</Button>
														) : (
															'-'
														)}
													</>
												)}
											</Box>
										</PopoverTrigger>
										<PopoverContent width='auto' zIndex={401}>
											<PopoverBody userSelect='none' whiteSpace='nowrap'>
												<HStack justifyContent={'space-between'}>
													<Input
														type={'number'}
														label={'Target'}
														isDisabled={insertInProgress}
														onChange={handleAttributeChange}
														value={attribute}
													/>
													<Button
														bg={'brand.800'}
														color={'white'}
														onClick={() => {
															sectionDetailedTrack({
																category: GA_CATEGORY_DASHBOARD,
																action: 'Widget Top Down Bottom Up View',
																label: 'Insert Update',
															})
															insertUpdateTarget(
																typeof targetObj?.[p] === 'number'
																	? true
																	: false,
																kpi.widget_type_id,
																queryFilters.selectedPeriodType?.label === 'QTD'
																	? p?.replace('_total_target_value', '')
																	: null
															)
														}}
													>
														{typeof targetObj?.[p] === 'number'
															? `Update`
															: `Save`}
													</Button>
												</HStack>
											</PopoverBody>
										</PopoverContent>
									</Popover>
								))}
								{typeof targetObj?.['y_total_target_value'] === 'number' &&
									isDisabled &&
									!accLevelAccess &&
									![17, 8, 14].includes(
										queryFilters.selectedPeriodType?.value
									) &&
									item.asset_flag_frontline === 1 && (
										<Button margin={'0 10px'}>
											<Tooltip label='Outlier'>
												<Box>
													<Checkbox
														size='md'
														className='outlier-checkbox'
														color={localStorage.getItem('color')}
														isChecked={flag_is_outlier === 1 ? true : false}
														isDisabled={true}
													/>
												</Box>
											</Tooltip>
										</Button>
									)}
								{typeof targetObj?.['y_total_target_value'] === 'number' &&
									!isDisabled &&
									!accLevelAccess &&
									![17, 8, 14].includes(
										queryFilters.selectedPeriodType?.value
									) &&
									item.asset_flag_frontline === 1 && (
										<Button margin={'0 10px'}>
											<Tooltip label={'Outlier'}>
												<Box>
													<Checkbox
														size='md'
														className='outlier-checkbox'
														color={localStorage.getItem('color')}
														defaultChecked={
															flag_is_outlier === 1 ? true : false
														}
														isChecked={
															outlierKpiDetails &&
															kpi.widget_type_id ===
																outlierKpiDetails.widget_type_id &&
															item.asset_id === outlierKpiDetails.asset_id
																? !!outlierKpiDetails?.flag_is_outlier
																: flag_is_outlier === 1
																? true
																: false
														}
														onChange={e =>
															setOutlierKpiDetails({
																...item,
																widget_type_id: kpi.widget_type_id,
																flag_is_outlier: e.target.checked ? 1 : 0,
															})
														}
														isDisabled={checkOutlierDisabled(item)}
													/>
												</Box>
											</Tooltip>
										</Button>
									)}
								{queryFilters.selectedPeriodType?.label === 'QTD' &&
									typeof targetObj?.[
										totalTargetType[queryFilters.selectedPeriod?.label]
									] === 'number' && (
										<Box
											fontWeight='bold'
											color='white'
											backgroundColor='brand.800'
											fontSize={18}
											padding={1}
										>
											<Tooltip
												hasArrow
												label={
													targetObj?.[
														totalTargetType[queryFilters.selectedPeriod?.label]
													]
												}
											>
												<span>
													{targetObj?.[
														totalTargetType[queryFilters.selectedPeriod?.label]
													]?.toFixed(2)}
												</span>
											</Tooltip>
										</Box>
									)}
							</Flex>
						) : (
							'-'
						)
				})

				data.push(obj)
			})
		}

		if (Object.values(total_targets)?.length) {
			let totals = {}
			let Total = (
				<Text
					fontWeight='bold'
					color='white'
					backgroundColor='brand.800'
					fontSize={18}
				>
					Total
				</Text>
			)
			totals['s.no'] = ''
			if (accLevelAccess) {
				totals['Account Code'] = Total
				totals['Account Name'] = ''
			} else {
				totals['Employee Name'] = Total
				totals['Employee Code'] = ''
				totals['Employee Email'] = ''
				totals['Employee Desk ID'] = ''
				totals['Manager Name'] = ''
				totals['Manager Code'] = ''
				totals['Manager Email'] = ''
				totals['Role'] = ''
				totals['Segment'] = ''
				totals['Vertical'] = ''
				totals['Circle'] = ''
			}
			kpiCodesList.forEach(kpi => {
				totals[kpi.widget_type_name] = (
					<Tooltip label={total_targets[kpi.widget_type_name]}>
						<Box
							as='span'
							fontWeight='bold'
							color='white'
							backgroundColor='brand.800'
							fontSize={18}
							padding={2}
							float='right'
						>
							{total_targets[kpi.widget_type_name]?.toFixed(2) || ''}
						</Box>
					</Tooltip>
				)
			})
			data.push(totals)
		}
		return data
	}

	const renderData = () => {
		let data = makeTableData()
		return data.map((item, i) => (
			<TR key={i}>
				{Object.values(item).map((val, j) => (
					<TD key={j}>{val}</TD>
				))}
			</TR>
		))
	}

	const handleDownload = () => {
		setDownload(true)
	}

	const exportHeadings = () => {
		let heads = []
		if (!isTopDown && accLevelAccess) {
			heads.push('Account Code', 'Account Name')
		} else {
			heads = [
				'Employee Name',
				'Employee Code',
				'Employee Email',
				'Employee Desk ID',
				'Manager Name',
				'Manager Code',
				'Manager Email',
				'Role',
				'Segment',
				'Vertical',
				'Circle',
			]
		}
		heads.push(
			...kpiCodesList.map(
				kpi => `${kpi.widget_type_code} (${kpi.widget_type_name})`
			)
		)
		return heads
	}

	const makeExportData = () => {
		let data = []
		let _arr = reporteesList
		if (accLevelAccess) {
			_arr = accCodesList
		}
		_arr?.forEach((item, i) => {
			let obj = {}
			obj['s.no'] = i + 1
			if (accLevelAccess) {
				obj['Account Code'] = item.customer_account_code
				obj['Account Name'] = item.customer_account_name
			} else {
				obj['Employee Name'] = item.operating_asset_first_name || 'TBA'
				obj['Employee Code'] = item.operating_asset_customer_unique_id
				obj['Employee Email'] = item.operating_asset_email_id
				obj['Employee Desk ID'] = item.asset_id
				obj['Manager Name'] = item.manager_operating_asset_first_name
				obj['Manager Code'] = item.manager_asset_id
				obj['Manager Email'] = item.manager_operating_asset_email_id
				obj['Role'] = item.asset_type_name
				obj['Segment'] = item.workforce_tag_name || item.workforce_name
				obj['Vertical'] = item.asset_tag_name_1
				obj['Circle'] = item.account_name
			}
			kpiCodesList.forEach(kpi => {
				let { target_value } = getTargetValue(kpi, item)
				obj[`${kpi.widget_type_code} (${kpi.widget_type_name})`] =
					kpi.asset_type_id === item.asset_type_id ||
					kpi.widget_type_id === item.widget_type_id ||
					(Array.isArray(item.widget_type_id) &&
						item.widget_type_id.includes(kpi.widget_type_id))
						? target_value
						: '-'
			})

			data.push(obj)
		})
		return data
	}

	const handleTemplateDownload = () => {
		let dataSet = makeTemplateData()
		if (dataSet.length === 0) {
			toast({
				title: `${
					queryFilters.selectedWorkforceTag?.value === 184
						? 'Channel Code'
						: 'KPI Code'
				} not present.`,
				position: `top-right`,
				isClosable: true,
				status: 'error',
			})
			return false
		} else {
			setTemplateDownload(true)
		}
	}

	const exportTemplateHeadings = () => {
		return [
			'Employee Name',
			'Employee Code',
			'Employee Email',
			'Employee Desk ID',
			'Role',
			'Cluster',
			'Vertical',
			'Circle',
			'Account Name / Channel Name',
			'Account Code / Channel Code',
			'KPI Name',
			'KPI Code',
			'Unit of Measurement',
			'Number formatting',
			'Outlier',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
			'Jan',
			'Feb',
			'Mar',
		]
	}

	const renderTemplateExportData = () => {
		let dataSet = makeTemplateData()
		const fileName = 'Download Template Bulk'
		setTimeout(() => {
			setTemplateDownload(false)
		}, 100)
		return (
			<ExcelFile filename={fileName} hideElement={true}>
				{[
					{
						sheet: 'Target Planning',
						data: dataSet,
						heads: exportTemplateHeadings(),
					},
					{
						sheet: 'Instructions',
						data: instructionsData,
					},
				].map(item => {
					return item.sheet === 'Target Planning' ? (
						<ExcelSheet data={item.data} name={item.sheet}>
							{item.heads?.map(headerItem => (
								<ExcelColumn
									label={headerItem === 'Apr' ? 'Target' : ''}
									value={headerItem}
								/>
							))}
						</ExcelSheet>
					) : (
						<ExcelSheet dataSet={item.data} name={item.sheet} />
					)
				})}
			</ExcelFile>
		)
	}

	const makeTemplateData = () => {
		let data = []
		let _arr = reporteesList
		if (accLevelAccess) {
			_arr = accCodesList
		}
		_arr?.forEach((item, i) => {
			let objData = kpiCodesList
				.filter(
					k =>
						k.asset_type_id === item.asset_type_id ||
						k.widget_type_id === item.widget_type_id ||
						(Array.isArray(item.widget_type_id) &&
							item.widget_type_id.includes(k.widget_type_id))
				)
				.map(kpi => {
					let { flag_is_outlier } = getTargetValue(kpi, item)
					let obj = {}
					obj['Employee Name'] =
						item.operating_asset_first_name ||
						activeUser.operating_asset_first_name ||
						'TBA'
					obj['Employee Code'] =
						item.operating_asset_customer_unique_id ||
						activeUser.operating_asset_customer_unique_id
					obj['Employee Email'] =
						item.operating_asset_email_id || activeUser.operating_asset_email_id
					obj['Employee Desk ID'] = item.asset_id || activeUser.asset_id
					obj['Role'] = item.asset_type_name || activeUser.asset_type_name
					obj['Cluster'] = ''
					obj['Vertical'] = item.asset_tag_name_1 || activeUser.asset_tag_name_1
					obj['Circle'] = item.account_name || activeUser.account_name
					if (accLevelAccess) {
						obj['Account Code / Channel Code'] = item.customer_account_code
						obj['Account Name / Channel Name'] = item.customer_account_name
					}
					obj['KPI Name'] = kpi.widget_type_name
					obj['KPI Code'] = kpi.widget_type_code
					obj['Unit of Measurement'] = kpi.widget_type_measurement_unit
					obj['Number formatting'] = kpi.widget_type_measurement_name
					if (!accLevelAccess) {
						obj['Outlier'] = flag_is_outlier ? 1 : 0
					}
					obj['Apr'] = ''
					obj['May'] = ''
					obj['Jun'] = ''
					obj['Jul'] = ''
					obj['Aug'] = ''
					obj['Sep'] = ''
					obj['Oct'] = ''
					obj['Nov'] = ''
					obj['Dec'] = ''
					obj['Jan'] = ''
					obj['Feb'] = ''
					obj['Mar'] = ''
					return obj
				})
			if (!!objData.length) {
				for (var d in objData) {
					data.push(objData[d])
				}
			}
		})
		let obj = {}
		obj['Employee Name'] = 'Employee Name'
		obj['Employee Code'] = 'Employee Code'
		obj['Employee Email'] = 'Employee Email'
		obj['Employee Desk ID'] = 'Employee Desk ID'
		obj['Role'] = 'Role'
		obj['Cluster'] = 'Cluster'
		obj['Vertical'] = 'Vertical'
		obj['Circle'] = 'Circle'
		obj['Account Name / Channel Name'] = 'Account Name / Channel Name'
		obj['Account Code / Channel Code'] = 'Account Code / Channel Code'
		obj['KPI Name'] = 'KPI Name'
		obj['KPI Code'] = 'KPI Code'
		obj['Unit of Measurement'] = 'Unit of Measurement'
		obj['Number formatting'] = 'Number formatting'
		obj['Outlier'] = 'Outlier'
		obj['Apr'] = 'Apr'
		obj['May'] = 'May'
		obj['Jun'] = 'Jun'
		obj['Jul'] = 'Jul'
		obj['Aug'] = 'Aug'
		obj['Sep'] = 'Sep'
		obj['Oct'] = 'Oct'
		obj['Nov'] = 'Nov'
		obj['Dec'] = 'Dec'
		obj['Jan'] = 'Jan'
		obj['Feb'] = 'Feb'
		obj['Mar'] = 'Mar'
		data.unshift(obj)
		return data
	}

	const renderExportData = () => {
		let dataSet = makeExportData()
		const { selectedPeriodType, selectedPeriod } = queryFilters
		const fileName = `Export Data ${selectedPeriodType?.label || ''} ${
			selectedPeriod?.label || ''
		}`
		setTimeout(() => {
			setDownload(false)
		}, 100)
		return (
			<ExcelFile filename={fileName} hideElement={true}>
				{[
					{
						sheet: 'Target Planning',
						data: dataSet,
						heads: exportHeadings(),
					},
				].map(item => (
					<ExcelSheet data={item.data} name={item.sheet}>
						{item.heads?.map(headerItem => (
							<ExcelColumn label={headerItem} value={headerItem} />
						))}
					</ExcelSheet>
				))}
			</ExcelFile>
		)
	}

	const loadData = reportee => {
		let { selectedWorkforceTag } = queryFilters
		setShowUserDetails(false)
		if (
			[184, 185].includes(selectedWorkforceTag?.value) &&
			reportee?.asset_flag_frontline === 1
		) {
			toast({
				title: `${
					queryFilters.selectedWorkforceTag?.value === 184
						? 'Channel Code'
						: 'Account Code'
				} not present.`,
				position: `top-right`,
				isClosable: true,
				status: 'error',
			})
			return
		}
		if (reportee?.asset_id) {
			setActiveUser(reportee)
		}
	}

	const handleBackToHome = () => {
		let reportee = { ...authData }
		if (reportee?.asset_id) {
			setShowUserDetails(true)
			setReporteesList([])
			addReportee([])
			addReporteeToStack(reportee, true)
			setActiveUser(reportee)
			;[{ name: 'selectedBuilding', option: 'buildingList' }]?.forEach(
				filter => {
					setQueryFilterValue(filter.name, filterOptions[filter.option]?.[0])
				}
			)
			const defaultFloor = {
				value: !!authData && authData.workforce_id,
				label: !!authData && authData.workforce_name,
				...authData,
			}
			setQueryFilterValue('selectedWorkforce', defaultFloor)
			const defaultCreator = {
				value: !!authData && authData.asset_id,
				label: !!authData && authData.operating_asset_first_name,
				...authData,
			}
			setQueryFilterValue('selectedCreator', defaultCreator)
		}
	}

	useEffect(() => {
		loadTeamMembers(activeUser)
	}, [activeUser, showUserDetails])

	const widgetMainView =
		isLoading || accLoading ? (
			<Stack>
				<Skeleton height='20px' />
				<Skeleton height='20px' />
				<Skeleton height='20px' />
			</Stack>
		) : (
			<Box>
				<VStack alignItems='flex-start' justifyContent={'flex-start'}>
					<Flex mb={0} p={3} alignItems='flex-start'>
						<Button
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_DASHBOARD,
									action: 'Widget Top Down Bottom Up View',
									label: 'Back Home',
								})
								handleBackToHome()
							}}
							mr={2}
						>
							<Icon as={BiHome} w={6} h={6} className={`cursor-pointer`} />
						</Button>
						<Box
							bg={'gray.100'}
							padding={'10px'}
							borderRadius={'5px'}
							d={'inline-flex'}
							mr={2}
						>
							{!!drillDownReporteeList &&
								drillDownReporteeList.map((item, i) => (
									<>
										<Text>&nbsp;{i !== 0 ? '/' : null}&nbsp;</Text>
										<Text
											fontWeight='bold'
											onClick={() => {
												sectionDetailedTrack({
													category: GA_CATEGORY_DASHBOARD,
													action: 'Widget Top Down Bottom Up View',
													label: 'Load Data',
												})
												item.asset_flag_frontline !== 0 && loadData(item)
											}}
											color={localStorage.getItem('color')}
											cursor='pointer'
											textDecoration='underline'
										>
											{item.operating_asset_first_name || 'TBA'}{' '}
										</Text>
									</>
								))}
						</Box>
						<Button
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_DASHBOARD,
									action: 'Widget Top Down Bottom Up View',
									label: 'Download',
								})
								handleDownload()
							}}
							mr={2}
						>
							<Icon
								as={BiDownload}
								w={6}
								h={6}
								mr={1}
								className={`cursor-pointer`}
							/>
							<Text
								fontWeight='bold'
								color={localStorage.getItem('color')}
								cursor='pointer'
							>
								Export Data
							</Text>
						</Button>
						<Button
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_DASHBOARD,
									action: 'Widget Top Down Bottom Up View',
									label: 'Template Download',
								})
								handleTemplateDownload()
							}}
						>
							<Icon
								as={BiDownload}
								w={6}
								h={6}
								mr={1}
								className={`cursor-pointer`}
							/>
							<Text
								fontWeight='bold'
								color={localStorage.getItem('color')}
								cursor='pointer'
							>
								Download Template Bulk
							</Text>
						</Button>
						{tempDownload && kpiCodesList.length
							? renderTemplateExportData()
							: null}
						{download && renderExportData()}
					</Flex>
				</VStack>
				<VStack spacing={8}>
					<DrillDownTable theadings={renderHeadings()} tdata={renderData()} />
				</VStack>
			</Box>
		)

	return (
		<Box bg='white' rounded='md' p='8px'>
			{isTimelineOpen && (
				<Drawer
					className={`form-add-onfly-drawer`}
					isOpen={isTimelineOpen}
					placement='right'
					onClose={onCloseTimeline}
					size='md'
				>
					<DrawerOverlay className={`form-add-onfly-drawer-overlay`} />
					<DrawerContent className={`form-add-onfly-drawer-content`}>
						<DrawerHeader fontSize='lg' fontWeight='900'>
							Target Timeline
						</DrawerHeader>
						<Divider />
						<DrawerCloseButton
							className={`form-add-onfly-drawer-close-button`}
						/>
						<DrawerBody className={`form-add-onfly-drawer-body`}>
							<VerticalTimeline layout='1-column-left' lineColor='gray'>
								{Array.isArray(timelineData) &&
									timelineData.map((timeline, i) => (
										<VerticalTimelineElement
											key={i.toString()}
											icon={<RiSettings2Fill size={'5px'} />}
											iconStyle={{
												background: 'rgb(233, 240, 245)',
												color: 'rgb(0,0,0)',
												boxShadow: 'none',
												border: '2px solid rgb(111, 186, 28)',
											}}
										>
											<div>
												<p
													style={{
														color: 'rgb(48,62,73)',
														fontSize: '80%',
														fontWeight: '300',
														margin: 0,
													}}
												>
													{formatDueDateForTimelineCurrDate(
														timeline.update_datetime
													)}
												</p>
												<p
													style={{
														color: 'black',
														fontSize: '80%',
														margin: 0,
														fontWeight: '300',
													}}
												>
													{timeline.update_type_name}
												</p>
											</div>
											<div
												style={{
													width: '98%',
													background: 'rgb(242, 242, 242)',
													boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px',
													marginTop: '1em',
													marginBottom: '1em',
													lineHeight: '1.6',
													padding: '0.5em 1em',
													fontSize: '80%',
													fontWeight: '300',
												}}
											>
												<p
													style={{
														margin: 0,
														fontSize: '11.2px',
														fontWeight: '300',
														color: 'black',
													}}
												>
													By:{' '}
													{`${timeline.log_asset_first_name} - ${timeline.log_asset_last_name}`}
												</p>
												<p
													style={{
														margin: 0,
														fontSize: '11.2px',
														fontWeight: '300',
														color: 'black',
													}}
												>
													Total Target value: {timeline?.[activePeriodTimeline]}
												</p>
											</div>
										</VerticalTimelineElement>
									))}
							</VerticalTimeline>
							{/* <Timeline>
              {Array.isArray(timelineData) &&
                timelineData.map((timeline, i) => {
                  return (
                    <TimelineEvent
                      key={i.toString()}
                      title={timeline.update_type_name}
                      createdAt={formatDueDateForTimelineCurrDate(
                        timeline.update_datetime
                      )}
                      contentStyle={{ background: '#f2f2f2' }}
                      icon={<RiSettings2Fill />}
                    >
                      By:{' '}
                      {`${timeline.log_asset_first_name} - ${timeline.log_asset_last_name}`}
                      <br />
                      Total Target value: {timeline?.[activePeriodTimeline]}
                    </TimelineEvent>
                  )
                })}
            </Timeline> */}
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			)}

			{widgetMainView}
		</Box>
	)
}

export default WidgetTopDownBottomUpView
